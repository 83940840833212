import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { startVolume } from '../../../store/stream/stream-slice';
import { AppDispatch } from '../../../store/store';
import { establishConnection } from '../../../store/stream/stream-operations';
import "./AllowVolume.scss";

interface AllowVolumeProps {
  setOverlayVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const AllowVolume: React.FC<AllowVolumeProps> = ({ setOverlayVisible }) => {
  const dispatch: AppDispatch = useDispatch();
  const [isCookieBannerVisible, setCookieBannerVisible] = useState(true);

  const handleAcceptVolume = async () => {
    try {
      // Запрашиваем разрешение на доступ к микрофону
      await navigator.mediaDevices.getUserMedia({ audio: true });
      console.log('Доступ к микрофону предоставлен');

      // Скрываем баннер и устанавливаем соединение
      setCookieBannerVisible(false);
      setOverlayVisible(false);
      dispatch(establishConnection());
      dispatch(startVolume(true));
    } catch (error) {
      console.log('Ошибка доступа к микрофону:', error);
    }
  };

  return (
    <>
      {isCookieBannerVisible && (
        <div className="cookie-banner" id="cookie-banner">
          <span>Разрешить воспроизводить звук и использовать микрофон</span>
          <button id="connect-button" onClick={handleAcceptVolume}>
            Разрешить
          </button>
        </div>
      )}
    </>
  );
};

export default AllowVolume;
