import React from 'react';
import ReactDOM from 'react-dom/client';
import '../src/assets/style.scss';
import App from './App';

// Импортируем Provider из react-redux
import { Provider } from 'react-redux';
// Импортируем созданное хранилище Redux
import store from './store/store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    {/* Оборачиваем App в Provider и передаём store */}
    <Provider store={store}>
        <App />
    </Provider>
  </React.StrictMode>,
);

