import React, { useImperativeHandle, forwardRef, useEffect, useRef, useState, useCallback } from 'react';
import axios from 'axios';
import RecordRTC from 'recordrtc';
import { useDispatch, useSelector } from 'react-redux';
import store, { AppDispatch, RootState } from '../../../store/store';
import { getStream } from '../../../utils/didApi';
import { resetInterrupted, setButtonActive, startVolume } from '../../../store/stream/stream-slice';
import { stopStreaming } from '../../../store/stream/stream-operations';
import { marked } from 'marked';
import styles from './ChatComponent.module.scss'
import {
  getChatMessages,
  getIsThinking,
  getThreadId,
  getTypingMessage,
  getIsStarterQuestion,
  getMute,
  getIsRecording,
  getIsStreamingLoading,
  getIsStartHelp,
  getSendButton,
  getMicroButton, getIsSkipped, getRecordFaild, getRecordFade,
} from '../../../store/chat/chat-selectors';
import { getIsStreaming, getIsStartVolume } from '../../../store/stream/stream-selectors';
import {
  setMessage,
  addMessageToChat,
  setIsThinking,
  setThreadId,
  setTypingMessage,
  setIsStarterQuestion,
  setMute,
  setIsRecording,
  setIsInputDisabled,
  setIsStreaming,
  setIsStreamingLoading,
  setIsStartHelp,
  setSendButton,
  setMicroButton,
  clearChatHistory,
  setIsReload, setIsSkipped,
} from '../../../store/chat/chat-slice';
import SvgIcon from '../../shared/icons/SvgIcon';
import useLongPress from '../../../hooks/useLongPress';

export interface ChatComponentHandle {
  restart: () => void;
}

const ChatComponent = forwardRef<ChatComponentHandle>((props, ref) => {
  const dispatch: AppDispatch = useDispatch();
  const message = useSelector((state: RootState) => state.chat.message);
  const messages = useSelector(getChatMessages);
  const isThinking = useSelector(getIsThinking);
  const threadId = useSelector(getThreadId);
  const typingMessage = useSelector(getTypingMessage);
  const isStarterQuestion = useSelector(getIsStarterQuestion);
  const mute = useSelector(getMute);
  const isRecording = useSelector(getIsRecording);
  const isStreaming = useSelector(getIsStreaming);
  const isStreamingLoading = useSelector(getIsStreamingLoading);
  const isStartHelp = useSelector(getIsStartHelp);
  const sendButton = useSelector(getSendButton);
  const microButton = useSelector(getMicroButton);
  const isStartVolume = useSelector(getIsStartVolume);
  const recordFaild = useSelector(getRecordFaild);
  const getState = useSelector((state: RootState) => state);
  const [charLimit] = useState(200);
  const apiUrl = process.env.REACT_APP_API_URL;
  const apiUrlRec = process.env.REACT_APP_API_URL_REC;
  const isButtonActive = useSelector((state: RootState) => state.stream.isButtonActive);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedReactions, setSelectedReactions] = useState<{ [key: string]: string }>({});
  const isInterruptedRef = useRef(false);
  const [isChatMobShow, setIsChatMobShow] = useState(false);
  const isSkipped = useSelector(getIsSkipped)
  const recordFade = useSelector(getRecordFade)


  const [recorder, setRecorder] = useState<RecordRTC | null>(null);
  const [isClickedMicro, setIsClickedMicro] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1024); // Проверка, меньше ли ширина 1024px
    };

    handleResize(); // Вызвать функцию один раз при монтировании
    window.addEventListener('resize', handleResize); // Добавить обработчик события изменения размера

    return () => {
      window.removeEventListener('resize', handleResize); // Удалить обработчик при размонтировании
    };
  }, []);

  const starterQuestions = [
    'Что ты умеешь?',
    'Откуда берешь данные?',
    'Как я могу тебя использовать?',
  ];

  const handleSendMessage = async (userMessage: string) => {
    if (!userMessage || userMessage.length > charLimit) return;
    dispatch(setIsReload(false));
    // Сбросим флаг рестарта при новом запросе

    dispatch(setMicroButton(false));
    dispatch(setIsStreamingLoading(true));
    dispatch(addMessageToChat({ user: true, content: userMessage, id: 'null' }));
    dispatch(setIsStartHelp(false));
    dispatch(setIsThinking(true));
    dispatch(setMessage(''));
    dispatch(setTypingMessage(''));
    dispatch(setButtonActive(true));

    dispatch(resetInterrupted());

    try {
      const response = await sendMessageToApi(userMessage);
      const currentIsReload = store.getState().chat.isReload;
      // Проверка перед обработкой ответа
      if (!currentIsReload) {
        await handleResponses(response);
      }
    } catch (error) {
    } finally {
      dispatch(setIsThinking(false));
      dispatch(setButtonActive(false));
      dispatch(setSendButton(false));
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleResponses = async (responseData: any) => {
    const messages = responseData.messages;
    const currentIsReload = store.getState().chat.isReload;

    // Проверяем флаг перезагрузки перед началом обработки
    if (currentIsReload) {
      return;
    }

    for (let i = 0; i < messages.length; i++) {
      const currentIsReload = store.getState().chat.isReload;
      if (isInterruptedRef.current || currentIsReload) {
        dispatch(setMicroButton(false));
        return; // Проверяем флаг перезагрузки
      }

      const message = messages[i];

      if (message.role === 'SYSTEM' && message.content) {
        dispatch(setIsThinking(true));
        if (isInterruptedRef.current || currentIsReload) {
          dispatch(setMicroButton(false));
          dispatch(setIsStreaming(false));
          return; // Проверяем флаг перезагрузки
        }
        const streamingPromise = message.audio_url
          ? getStream(message.audio_url, dispatch, store.getState) // Используйте store.getState
          : Promise.resolve();

        streamingPromise.then(() => {
          if (isInterruptedRef.current || currentIsReload) {
            dispatch(setIsStreaming(false));
            handleStop()
          }
          // Обрабатываем ответ, если запрос не был прерван
        }).catch((err) => {
          console.error('An error occurred:', err);
        });

        if (message.audio_url) {
          await streamingPromise;
        }
        await new Promise(resolve => setTimeout(resolve, 3000));
        if (isInterruptedRef.current || currentIsReload) {

          dispatch(clearChatHistory());
          dispatch(setIsStreaming(false));
          dispatch(setMicroButton(false));
          return
        }

        if (!isInterruptedRef.current && !currentIsReload) {
          dispatch(setIsThinking(false));
          dispatch(setIsStreaming(true));
        } else {
          dispatch(setIsStreaming(false));
          return; // Проверяем флаг перезагрузки
        }


        // Начинаем симуляцию печати
        const typingPromise = simulateTypingEffect(message.source_url, message.content, message.id);
        await typingPromise;

        if (isInterruptedRef.current || currentIsReload) {
          dispatch(setMicroButton(false));
          dispatch(setIsStreaming(false));
          return; // Проверяем флаг перезагрузки
        }

        // После завершения печати, если нужно, устанавливаем isStreaming в false
        dispatch(setButtonActive(false));
        dispatch(setSendButton(false));
        dispatch(setIsStreaming(false));
        dispatch(setIsStreamingLoading(false));
        dispatch(setMicroButton(true));
      }
    }
  };

  const sendMessageToApi = async (userMessage: string) => {
    dispatch(setIsStarterQuestion(false));
    isInterruptedRef.current = false;
    const url = threadId ? `${apiUrl}/add_message` : `${apiUrl}/start_message`;
    const payload = threadId
      ? { user_message: userMessage, thread_id: threadId }
      : { user_message: userMessage };
    dispatch(resetInterrupted());
    const response = await axios.post(url, payload);

    if (!threadId && response.data.thread_id) {
      dispatch(setThreadId(response.data.thread_id));
    }

    return response.data;
  };

  const simulateTypingEffect = async (sourceUrls: string[], responseText: string, id: string) => {
    const currentIsReload = store.getState().chat.isReload;
    if (isInterruptedRef.current || currentIsReload) return;

    let formattedMessage = '';
    let isInterruptedOccured = false;

    for (let i = 0; i < responseText.length; i++) {
      if (isInterruptedRef.current) {
        if (!isInterruptedOccured) {
          dispatch(addMessageToChat({ user: false, content: formattedMessage, id }));
          dispatch(setTypingMessage(''));
          isInterruptedOccured = true;
        }
        dispatch(setIsStreaming(false));
        return;
      }

      await new Promise(resolve => setTimeout(resolve, 59));

      formattedMessage += responseText[i];
      dispatch(setTypingMessage(formattedMessage)); // Отображаем текст без парсинга пока идёт печать

      if (i === responseText.length - 1 && !isInterruptedOccured) {
        // Добавляем ссылки в конце сообщения, если они есть
        let finalMessageWithLinks = formattedMessage;

        if (sourceUrls && sourceUrls.length > 0) {
          let sourcesHtml = '<br/><br/>Источники:<ul>';
          sourceUrls.forEach(url => {
            sourcesHtml += `<li><a href="${url}" target="_blank">${url}</a></li>`;
          });
          sourcesHtml += '</ul>';

          // Важно: используем innerHTML для корректного рендеринга HTML
          finalMessageWithLinks += sourcesHtml;
        }

        // Добавляем финальное сообщение с разметкой и ссылками в HTML
        dispatch(addMessageToChat({ user: false, content: finalMessageWithLinks, id }));
        dispatch(setTypingMessage(''));
      }
    }

    dispatch(setIsStreamingLoading(false));
  };

  const handleStop = () => {
    dispatch(setMicroButton(false));
    dispatch(setIsThinking(false));
    dispatch(setTypingMessage(''));
    isInterruptedRef.current = true;
    dispatch(setIsStreamingLoading(true));
    dispatch(stopStreaming());
    dispatch(setIsStreaming(false));
    dispatch(setButtonActive(false));

    dispatch(setSendButton(false));

    setTimeout(() => {
      isInterruptedRef.current = false;
      dispatch(resetInterrupted());
      dispatch(setIsStreaming(false));
    }, 3000);
    setTimeout(() => {
      dispatch(setIsStreaming(false));
      dispatch(setIsStreamingLoading(false));
      dispatch(setMicroButton(true));
    }, 10000);
  };

  const handleStarterQuestionClick = (question: string) => {
    dispatch(setIsStarterQuestion(false));
    handleSendMessage(question);
  };

  const chatHistoryRef = useRef<HTMLDivElement>(null);
  const [isAutoScroll, setIsAutoScroll] = useState(true);

  const scrollToBottom = () => {
    if (chatHistoryRef.current && isAutoScroll) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  const handleScroll = () => {
    if (chatHistoryRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatHistoryRef.current;
      if (scrollHeight - scrollTop === clientHeight) {
        setIsAutoScroll(true);
      } else {
        setIsAutoScroll(false);
      }
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSendMessage(message);
  };

  const handleMute = () => {
    dispatch(setMute(!mute));
  };

  useEffect(() => {
    const videos = document.querySelectorAll('video');
    videos.forEach(video => {
      if (mute !== null) {
        video.muted = mute;
      }
    });
  }, [mute]);

  const handleRecord = async () => {
    if (isRecording) {
      if (recorder) {
        recorder.stopRecording(async () => {
          const audioBlob = recorder.getBlob();
          const reader = new FileReader();
          reader.onloadend = async () => {
            const base64Audio = reader.result?.toString().split(',')[1];
            try {
              const response = await axios.post(apiUrlRec || '', {
                audio: base64Audio,
              });
              const text = response.data.text;

              if (text && text.trim() !== '') {
                handleSendMessage(text);
                dispatch(setSendButton(true));
                dispatch(setMicroButton(false));

              }
              dispatch(setIsInputDisabled(false));
            } catch (error) {
              console.error('Ошибка при отправке аудио:', error);
            }
          };
          reader.readAsDataURL(audioBlob);
        });
        dispatch(setIsRecording(false));
      }
    } else {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        const newRecorder = new RecordRTC(stream, { type: 'audio' });
        newRecorder.startRecording();
        setRecorder(newRecorder);
        dispatch(setIsRecording(true));
        dispatch(setIsInputDisabled(true));
      } catch (error) {
        console.error('Ошибка при получении доступа к микрофону:', error);
      }
    }
  };

  const handleSendRecord = (text: string) => {
    if (text && text.trim() !== '') {
      handleSendMessage(text);
      dispatch(setSendButton(true));
      dispatch(setMicroButton(false));
    }
  };


  const onLongPress = () => {
    dispatch(setIsRecording(true));
    setFadeOut(true);
    setTimeout(() => setIsClickedMicro(false), 500);
  };

  const onClick = () => {
    setIsClickedMicro(true);
    setFadeOut(false);

    // Скрываем блок через 5 секунд
    setTimeout(() => {
      setFadeOut(true); // Запускаем анимацию исчезновения
      setTimeout(() => setIsClickedMicro(false), 500); // Скрываем блок через 500мс (время анимации)
    }, 1000);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions, handleSendRecord);
  const { recordingTime } = longPressEvent; // Capture recording time from the hook

  const formatTime = (totalMilliseconds: number) => {
    const seconds = Math.floor(totalMilliseconds / 1000); // Total seconds
    const millis = Math.floor((totalMilliseconds % 1000) / 10); // Convert milliseconds to tenths
    return `${String(seconds).padStart(2, '0')}:${String(millis).padStart(2, '0')}`; // Format as SS:MS
  };


  useImperativeHandle(ref, () => ({
    restart() {
      handleRestart();
    },
  }));

  const handleRestart = () => {
    // Устанавливаем флаг перезагрузки сразу
    dispatch(setIsReload(true));
    handleStop(); // Останавливаем печатание
    dispatch(clearChatHistory()); // Очищаем историю сообщений
    dispatch(setMessage('')); // Сбрасываем текущее сообщение
    dispatch(setThreadId(null)); // Сбрасываем идентификатор потока
    dispatch(setIsStarterQuestion(true)); // Возвращаем стартовые вопросы
    dispatch(setIsInputDisabled(false)); // Разблокируем инпут
    dispatch(setIsStreaming(false)); // Устанавливаем состояние "не стримит"
    dispatch(setIsStartHelp(true)); // Возвращаем помощь на стартовое состояние
    dispatch(setButtonActive(false)); // Деактивируем кнопку

    // Убедимся, что очистка истории сообщений произойдет с учетом флага
    setTimeout(() => {
      const currentIsReload = store.getState().chat.isReload; // Получаем актуальное состояние
      // Здесь используем флаг для выполнения логики
      if (currentIsReload) {
        dispatch(clearChatHistory());
      }
    }, 100);
  };

  // useEffect(() => {
  //   dispatch(setIsReload(true));
  // }, [isInterruptedRef.current]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setMessage(e.target.value));

    if (e.target.value.length > 0) {
      dispatch(setSendButton(true));
      dispatch(setMicroButton(false));
    } else {
      dispatch(setSendButton(false));
      dispatch(setMicroButton(true));
    }
  };

  const handleCancelRecording = () => {
    if (recorder) {
      recorder.reset();
      setRecorder(null);
      dispatch(setIsRecording(false));
      dispatch(setIsInputDisabled(false));
    }
  };

  const handleShow = () => {
    setIsChatMobShow(!isChatMobShow); // Инвертируем значение состояния
  };

  const handleReactionClick = async (messageId: string | undefined, reaction: string) => {
    if (!messageId) return;

    setSelectedReactions(prevReactions => ({
      ...prevReactions,
      [messageId]: reaction,
    }));

    const isPositiveReaction = reaction === 'like';

    try {
      const response = await axios.post(`${apiUrl}/add_reaction`, {
        message_id: messageId,
        reaction: isPositiveReaction,
      });

    } catch (error) {
      console.error('Error sending reaction:', error);
    }
  };

  const handleSkip = () => {
    dispatch(setIsSkipped(true))
  }

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      const isMobile = window.innerWidth < 1024;

      if (
        target.tagName === 'INPUT' ||
        target.id === 'recognizeButton' ||
        target.classList.contains('starterQuestion') ||
        (isMobile && target.closest('#content'))
      ) {
        setFadeOut(true);
        setTimeout(() => setIsClickedMicro(false), 500);
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, []);

  return (
    <div
      className={`${styles.chatContainer} ${isMobile ? styles.mobile : ''} ${isChatMobShow ? styles.show : ''}`}
      id="chatContainer"
    >
      <div className={isMobile ? `${styles.menuTop} ${styles.mob}` : styles.menuTop}>
        <div className={styles.muteContainer} onClick={handleMute}>
          <div
            className={
              !isStartVolume
                ? `${styles.muteBack} ${styles.skeleton}`
                : mute
                  ? `${styles.muteBack} ${styles.active}`
                  : styles.muteBack
            }
            id="muteContainer"
          >
            <div className={styles.muteMic} style={!isStartVolume ? { display: 'none' } : {}}>
              <div id="mute" className={mute ? `${styles.mute} ${styles.active}` : styles.mute}></div>
            </div>
          </div>
          <div className={!isStartVolume ? `${styles.muteBack} ${styles.none} ${styles.skeleton}` : ''}></div>
        </div>
        {messages.length > 0 && (
          <div className={styles.muteContainer} onClick={handleRestart}>
            <div className={`${styles.muteBack} ${styles.restartContainer}`} id="restartContainer">
              <div id="restart" className={styles.restart}></div>
            </div>
          </div>
        )}
      </div>

      <div
        id="msgHistory"
        className={`${styles.chatContainerHistory} ${isMobile ? styles.mob : ''} ${isChatMobShow ? styles.show : ''}`}
        ref={chatHistoryRef}
        onScroll={handleScroll}
      >
        <div className={styles.chatContent}>
          {/* Стартовые вопросы */}
          {isStartHelp && (
            <div className={!isStartVolume ? `${styles.message} ${styles.system} ${styles.skeleton} ${styles.starter}` : `${styles.message} ${styles.system} ${styles.starter}`}>
            <span>
              {!isStartVolume ? 'Здравствуйте, Я - Артем. Напишите ваш вопрос или выберите ниже' : 'Здравствуйте, Я - Артем. Напишите ваш вопрос или выберите ниже👇🏼'}
            </span>
            </div>
          )}

          <div
            id="starter-questions"
            className={
              !isStartVolume
                ? `${styles.starterQuestions} ${styles.skeleton}`
                : isStarterQuestion
                  ? styles.starterQuestions
                  : `${styles.starterQuestions} ${styles.active}`
            }
          >
            {starterQuestions.map((question, index) => (
              <button
                key={index}
                className={!isStartVolume ? `${styles.starterQuestion} ${styles.skeleton}` : styles.starterQuestion}
                onClick={() => handleStarterQuestionClick(question)}
                disabled={isButtonActive || isStreamingLoading }
              >
                {question}
                <div className={styles.starterQuestionArrow} style={!isStartVolume ? { display: 'none' } : {}}></div>
              </button>
            ))}
          </div>
          {messages.map((msg) => {
            if (!msg.id) return null;
            return (
              <div key={msg.id} className={`${styles.message} ${msg.user ? styles.user : styles.system}`}>
                <div className={styles.messageContainer}>
                  <div className={styles.messageText} dangerouslySetInnerHTML={{ __html: msg.content }} />
                  {!msg.user &&
                    (selectedReactions[msg.id] ? (
                      <div className={styles.selectedReaction}>
                      <span
                        className={
                          selectedReactions[msg.id] === '👍🏼'
                            ? `${styles.likeIcon} ${styles.active}`
                            : `${styles.dislikeIcon} ${styles.active}`
                        }
                      ></span>
                      </div>
                    ) : (
                      <div className={styles.reactionContainer}>
                        <button className={styles.like} onClick={() => handleReactionClick(msg.id || '', '👍🏼')}>
                          <span className={styles.likeIcon}></span>
                        </button>
                        <button className={styles.like} onClick={() => handleReactionClick(msg.id || '', '👎🏼')}>
                          <span className={styles.dislikeIcon}></span>
                        </button>
                      </div>
                    ))}
                </div>
              </div>
            );
          })}
          {isThinking && (
            <div className={`${styles.message} ${styles.system}`}>
              Думаю<span className={styles.dot}></span>
              <span className={styles.dot}></span>
              <span className={styles.dot}></span>
            </div>
          )}
          {typingMessage && (
            <div className={`${styles.message} ${styles.system}`} dangerouslySetInnerHTML={{ __html: typingMessage }}></div>
          )}
        </div>
      </div>

      <form
        id="chat-form"
        className={
          isButtonActive || isStreamingLoading
            ? isClickedMicro || recordFaild
              ? `${styles.chatContainerInput} ${styles.round} ${styles.hidden}`
              : `${styles.chatContainerInput} ${styles.hidden}`
            : sendButton
              ? isClickedMicro || recordFaild
                ? `${styles.chatContainerInput} ${styles.round} ${styles.active}`
                : `${styles.chatContainerInput} ${styles.active}`
              : isClickedMicro || recordFaild
                ? `${styles.chatContainerInput} ${styles.round}`
                : styles.chatContainerInput
        }
        style={!isStartVolume ? { background: '#D9D9D9' } : {}}
        onSubmit={handleFormSubmit}
      >
        {isMobile && (
          <div className={!isStartVolume ? `${styles.menuMobile} ${styles.skeleton}` : styles.menuMobile}>
            <button type="button" onClick={handleShow}>
              {isChatMobShow ? 'Назад' : 'Чат'}
            </button>
          </div>
        )}
        <input
          ref={inputRef}
          id="textArea"
          className={
            !isStartVolume || isStreamingLoading
              ? `${styles.chatContainerTextarea} ${styles.skeleton}`
              : !isButtonActive
                ? styles.chatContainerTextarea
                : `${styles.chatContainerTextarea} ${styles.hidden}`
          }
          placeholder={!isStartVolume ? '' : 'Напишите сообщение'}
          value={message}
          onChange={handleInputChange}
          style={isRecording ? { display: 'none' } : {}}
          disabled={isButtonActive || isRecording || isThinking || isStreaming || isStreamingLoading}
        />
        {!isStreamingLoading && microButton && isClickedMicro && (
          <div className={`${styles.holdMicro} ${fadeOut ? styles.fadeOut : ''}`}>
            <div>Для использования микрофона нажмите и удерживайте кнопку👇🏼</div>
          </div>
        )}
        {recordFaild && (
          <div className={`${styles.holdMicro} ${recordFade ? styles.fadeOut : ''}`}>
            <div>Ошибка записи сообщения, попробуйте еще раз.</div>
          </div>
        )}
        <div className={styles.recordStatus}>
          <button
            className={styles.flashingButton}
            style={!isRecording ? { display: 'none' } : {}}
          ></button>
          <div className={styles.timerRecord} style={!isRecording ? { display: 'none' } : {}}>
            {formatTime(recordingTime)}
          </div>
        </div>

        <div className={styles.textRecord}
             style={{ ...longPressEvent.textStyle, display: !isRecording ? 'none' : 'block' }}>
          Сдвиньте для отмены
        </div>

        <div className={styles.inputButton} style={!isStartVolume ? { display: 'none' } : {}}>
          {!isSkipped && (
            <button onClick={handleSkip} type="button" id="skip" className={styles.stopButtonContainer}>
              <button id="stopButton" className={styles.stopButton}></button>
            </button>
          )}
          {!isStreamingLoading && microButton && (
            <>
              <button
                id="recognizeButton"
                className={`${styles.recognizeButton} ${styles.chatContainerSendButton} ${isRecording ? styles.active : ''}`}
                aria-label="Start Recording"
                type="button"
                {...longPressEvent}
                disabled={false}
                style={longPressEvent.buttonStyle}
              >
                <div className={styles.submitContainer}>
                  <div id="circleMicrophone" className={styles.circleMicrophone}></div>
                </div>
              </button>
            </>
          )}
          {isStreamingLoading ? (
            isStreaming ? (
              <button
                onClick={handleStop}
                type="button"
                disabled={!isStreaming}
                id="stop-button-container"
                className={styles.stopButtonContainer}
              >
                <button id="stop-button" className={styles.stopButton}></button>
              </button>
            ) : (
              <button
                onClick={handleStop}
                type="button"
                disabled={!isStreaming}
                id="stop-button-container loading"
                className={`${styles.stopButtonContainer} ${styles.loading}`}
              >
                <button id="stop-button" className={`${styles.stopButton} ${styles.loading}`}></button>
              </button>
            )
          ) : (
            sendButton && (
              <button className={`${styles.chatContainerSendButton} ${styles.send} ${styles.sendButton}`}
                      onClick={() => handleSendMessage(message)}
                      type="button"
                      id="sendButton"
                      disabled={isThinking || isButtonActive} // Disable button while thinking
              >
                <div className={`${styles.svg}`}>
                  <SvgIcon className='Layer_1' name='Layer_1' />
                </div>
              </button>
            )
          )}
        </div>
      </form>
    </div>
  );
});

export default ChatComponent;
